<template>
  <div class="list-box" v-loading="loading">
    <div
      class="item"
      v-for="item in dataList"
      :key="item.fk_course_id"
      @click="openDetail(item.fk_course_id)"
    >
      <div class="item-img-box">
        <img class="item-img" :src="item.img || kong" alt="" />
        <div class="live-tip" v-if="item.type == 2">直播中</div>
        <div class="mask">
          <img class="mask-icon" src="@/assets/home/ic_1.png" alt="" />
        </div>
      </div>
      <div class="item-content">
        <div class="content-title line-2">
          {{ item.name }}
        </div>
        <div class="content-type">
          <div class="type-class">年级：{{ item.grade }}</div>
          <div class="type-line"></div>
          <div class="type-obj">学科：{{ item.subject }}</div>
        </div>
        <div class="content-bottom-live" v-if="item.type != 1">
          {{ item.live_status != 3 ? "开播时间" : "结束时间" }}：{{
            item.startTime
          }}
        </div>
        <div class="content-bottom" v-else>
          <span>课程章节数{{ item.course_chapter_count }}</span>
        </div>
      </div>
    </div>
    <div
      class="item"
      style="background-color: transparent; cursor: default"
      v-for="item in dataList.length > 0 ? 4 - (dataList.length % 4) : 0"
      :key="item"
    ></div>
    <div class="kong" v-if="dataList.length <= 0">
      <el-empty
        image="https://eduapi.114kaili.com/Content/images/no_data.png"
        :image-size="303"
        description="暂无数据"
      ></el-empty>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    openDetail(id) {
      this.$router.push({
        name: "study_detail",
        query: {
          id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.list-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 58px;
  .item {
    width: calc(25% - 12px);
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
    margin-bottom: 12px;
    cursor: pointer;
    .item-img-box {
      width: 100%;
      height: 164px;
      position: relative;
      .item-img {
        height: 100%;
      }
      &:hover .mask {
        opacity: 1;
      }
      .mask {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        opacity: 0;
        transition: opacity 0.5s ease;
        position: absolute;
        top: 0;
        left: 0;
        .mask-icon {
          width: 60px;
          height: 60px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .live-tip {
        position: absolute;
        top: 0;
        right: 0;
        width: 54px;
        height: 24px;
        background: #1d2088;
        border-radius: 0px 10px 0px 10px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;

        font-weight: 400;
      }
    }
    .item-content {
      flex: 1;
      padding: 14px 18px;
      box-sizing: border-box;
      .content-title {
        flex: 1;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: #000000;
        margin-bottom: 14px;
      }
      .content-type {
        display: flex;
        align-items: center;
        font-size: 14px;

        font-weight: 400;
        color: #666666;
        margin-bottom: 18px;
        .type-line {
          margin: 0 5px;
          width: 1px;
          height: 14px;
          background-color: #999;
        }
      }
      .content-bottom-live {
        padding-top: 14px;
        border-top: 1px solid #ececec;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        display: flex;
        align-items: center;
      }
      .content-bottom {
        padding-top: 14px;
        border-top: 1px solid #ececec;
        font-size: 14px;
        font-weight: 400;
        color: #1d2088;
        display: flex;
        align-items: center;
      }
    }
  }
  .kong {
    width: 100%;
    margin: 0 auto;
  }
}
</style>
