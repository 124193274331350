<template>
  <div class="teacherTraining">
    <div class="content-box">
      <div class="search-box">
        <img
          class="search-img"
          src="../../assets/teacherTraining/banner3.png"
          alt=""
        />
        <div class="search">
          <div class="input-box">
            <el-input
              v-model="searchForm.keywords"
              placeholder="请输入搜索关键字.."
              :clearable="true"
              @clear="initPage"
            ></el-input>
          </div>
          <div class="search-btn" @click="initPage">搜索</div>
        </div>
      </div>
    </div>
    <div class="nav-box">
      <div class="nav-content">
        <el-carousel
          :loop="false"
          :autoplay="false"
          height="120"
          arrow="always"
          indicator-position="none"
        >
          <el-carousel-item v-for="(item, index) in navList" :key="index">
            <div class="nav-list">
              <div
                v-for="items in item"
                :key="items.fk_category_id"
                :class="[
                  'nav-item',
                  searchForm.fk_category_id == items.fk_category_id
                    ? 'active'
                    : '',
                ]"
                @click="changNav(items.fk_category_id)"
              >
                <img class="item-icon" :src="items.img" alt="" />
                <span>{{ items.name }}</span>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="onLine">
      <div class="title">云端直播</div>
      <div class="nav-box1">
        <div class="nav-type" v-if="classList.length > 0">
          <div class="type-title">年级：</div>
          <div class="type-list">
            <div
              :class="[
                'type-item',
                item.fk_category_id == searchForm.fk_grade_id ? 'active' : '',
              ]"
              v-for="(item, index) in classList"
              :key="index"
              @click="changClass(item.fk_category_id)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="nav-type" v-if="subjectList.length > 0">
          <div class="type-title">学科：</div>
          <div class="type-list">
            <div
              :class="[
                'type-item',
                item.fk_category_id == searchForm.fk_subject_id ? 'active' : '',
              ]"
              v-for="(item, index) in subjectList"
              :key="index"
              @click="changSubject(item.fk_category_id)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <teacher-training-line
        :dataList="dataList"
        :loading="dataListParams.loading"
      />
      <div class="pagination">
        <el-pagination
          @current-change="handleCurrentChange"
          :background="true"
          @prev-click="handleCurrentChange"
          @next-click="handleCurrentChange"
          :page-size="dataListParams.pageSize"
          layout="total, prev, pager, next"
          :total="dataListParams.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import teacherTrainingLine from "./components/teacherTrainingLine";
import tableMinxin from "@/mixins/tableMinxin.js";
import Api from "@/api";
export default {
  name: "teacher_study",
  components: {
    teacherTrainingLine,
  },
  mixins: [tableMinxin],
  data() {
    return {
      navList: [],
      categoryList: [],
      classList: [],
      subjectList: [],
      searchForm: {
        fk_category_id: 0,
        fk_grade_id: 0,
        fk_subject_id: 0,
        is_red: 99,
        keywords: "",
        type: 3,
      },
    };
  },
  mounted() {
    if (this.$route.query.type) {
      this.searchForm.type = this.$route.query.type;
    }
    Promise.all([
      Api.Category({
        type: 1,
      }),
      // Api.Category({
      //   type: 2,
      // }),
      // Api.Category({
      //   type: 3,
      // }),
    ]).then((res) => {
      let arr = [];
      let navList = res[0].data;
      for (let i = 0; i < navList.length; i += 5) {
        arr.push(navList.slice(i, i + 5));
      }
      this.navList = arr;
      // this.classList = res[1].data;
      // this.subjectList = res[2].data;
    });
    this.getTitle(7);
    this.initPage();
  },
  methods: {
    initPage() {
      this._init_data("Course", {
        ...this.searchForm,
      });
    },
    changCategory(id) {
      this.searchForm.fk_category_id = id;
      this.initPage();
    },
    changClass(id) {
      this.searchForm.fk_grade_id = id;
      this.initPage();
    },
    changSubject(id) {
      this.searchForm.fk_subject_id = id;
      this.initPage();
    },
    changNav(type) {
      this.searchForm.fk_category_id = type;
      Api.CourseGrade({
        fk_business_id: this.$store.state.businessId,
        fk_category_id: type,
      }).then((res) => {
        this.classList = res.data || [];
      });
      Api.CourseSubject({
        fk_business_id: this.$store.state.businessId,
        fk_category_id: type,
      }).then((res) => {
        this.subjectList = res.data || [];
      });
      this.initPage();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  height: 50px;
  font-size: 15px;
  border: none;
}
::v-deep .el-pager li {
  background: transparent;
}
::v-deep .el-pagination .btn-next,
.el-pagination .btn-prev {
  background: transparent;
}
::v-deep .el-input__suffix {
  .el-icon-circle-close:before {
    position: relative;
    top: 4px;
    font-size: 20px;
  }
}
::v-deep .el-carousel__arrow {
  background-color: transparent;
  font-size: 40px;
  color: #cacaca;
}
::v-deep .el-carousel__container {
  height: 75px !important;
}
::v-deep .el-icon-arrow-right，el-icon-arrow-left {
  position: relative;
  bottom: 6px;
}
.teacherTraining {
  width: 100%;
  background: url("../../assets/teacherTraining/banner4.png") no-repeat;
  background-position: center top;
  background-color: #f5f5f5;
  .content-box {
    width: 1200px;
    margin: 0 auto;
    .search-box {
      padding-top: 30px;
      width: 603px;
      display: flex;
      flex-direction: column;
      .search-img {
        width: 60%;
        height: auto;
      }
      .search {
        width: 90%;
        height: 50px;
        display: flex;
        align-items: center;
        padding-top: 25px;
        .input-box {
          flex: 1;
          height: 100%;
          border-radius: 10px 0 0 10px;
          overflow: hidden;
        }
        .search-btn {
          width: 115px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #0086ff;
          color: #fff;
          font-size: 18px;
          border-radius: 0px 10px 10px 0px;
          cursor: pointer;
        }
      }
    }
  }
  .nav-box {
    width: 100%;
    height: 75px;
    background-color: #fff;
    margin-top: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    position: relative;
    .nav-content {
      width: 1200px;
      height: 100%;
    }
    .nav-list {
      background-color: #fff;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      z-index: 10;
      .nav-item {
        height: 48px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 19px;
        color: #000000;
        border-radius: 8px;
        cursor: pointer;
        &:hover {
          background-color: #eaeaea;
        }
        .item-icon {
          width: 30px;
          height: 30px;
          margin-right: 7px;
          border: 1px #fff solid;
          border-radius: 20px;
          background-color: #fff;
        }
        &.active {
          background-color: #0086ff;
          color: #fff;
        }
      }
    }
  }
  .onLine {
    width: 1200px;
    margin: 0 auto;
    .title {
      font-size: 22px;

      font-weight: 400;
      color: #000000;
      padding-bottom: 22px;
      padding-top: 40px;
    }
    .nav-box1 {
      flex: 1;
      display: flex;
      flex-direction: column;
      .nav-type {
        display: flex;
        align-items: center;
        padding: 16px;
        border-top: 1px solid #bfbfbf;
        .type-title {
          font-size: 14px;

          font-weight: 400;
          color: #000000;
          padding-right: 40px;
        }
        .type-list {
          display: flex;
          align-items: center;
          .type-item {
            padding: 0 11px;
            height: 36px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;

            font-weight: 400;
            color: #000000;
            margin-right: 24px;
            cursor: pointer;
            &:hover {
              background-color: #eaeaea;
            }
            &.active {
              color: #fff;
              background-color: #0086ff;
            }
          }
        }
      }
    }
    .pagination {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-bottom: 70px;
    }
  }
}
</style>
